@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@600&display=swap');
/*
  1. Use a more-intuitive box-sizing model.
*/
*, *::before, *::after {
  box-sizing: border-box;
 
}
/*
  2. Remove default margin
*/
* {
  margin: 0;
}
/*
  3. Allow percentage-based heights in the application
*/

html,
body {
  height: 100%;
}
/*
  Typographic tweaks!
  4. Add accessible line-height
  5. Improve text rendering
*/
body {
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  font-family: Inter;
}
.bebas-neue {
  font-family: 'Bebas Neue', cursive;
}
.rubik{
  font-family: 'Rubik', sans-serif;
}
/*
  6. Improve media defaults
*/

img,
picture,
video,
canvas,
svg {
  display: block;
  max-width: 100%;
}
/*
  7. Remove built-in form typography styles
*/

input,
button,
textarea,
select {
  font: inherit;
}
/*
  8. Avoid text overflows
*/

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  overflow-wrap: break-word;
}
/*
  9. Create a root stacking context
*/
#root,
#__next {
  isolation: isolate;

}

.main-wrapper{
  width: min(1100px, 96%);
margin: 0 auto;
}
.card-wrapper{
  grid-template-columns: repeat(auto-fit,minmax(360px, 1fr));
}

.mobile-menu{
  transform: translateX(100%);
  transition: all ease-in 500ms;
}

.mobile-menu.expanded{
transform: translateX(0);
}